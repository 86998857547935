import React from 'react';
import './App.css';

function PrivateDiary() {
  return (
    <div className="private-diary-container">
      <h1>Private Diary</h1>
      <h2>Coming soon</h2>
      
      <p>If you are interested  to become a beta tester for the private diary, sign up below!</p>

      <a href="mailto:info.jhabits@gmail.com?subject=Beta Testing Jhabits&body=I would like to sign up for the beta testing of Jhabits. Thank you" className="email-signup-link">Sign up to become a beta tester</a>

    </div>
  );
}

export default PrivateDiary;

import React, { useState, useEffect } from 'react';

const API_HISTORY_URL = process.env.REACT_APP_API_HISTORY;

function PublicDiary() {
  const [diaryEntries, setDiaryEntries] = useState([]);

  useEffect(() => {
    const fetchDiaryEntries = async () => {
      try {
        const response = await fetch(API_HISTORY_URL);
        if (!response.ok) throw new Error('Diary entries could not be fetched');
        const data = await response.json();
        data.sort((a, b) => new Date(b.Time) - new Date(a.Time)); // Sort entries by time, newest first
        setDiaryEntries(data);
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };

    fetchDiaryEntries();
  }, []); // Dependency array remains empty for mount-only effect

  return (
    <div className="diary-entries">
      <h2>The Public Diary</h2>
      <p>What is being said in the 'Talk' section, appears here.</p>
      {diaryEntries.map((entry, index) => (
        <div key={index} className="diary-entry">
          <p><strong></strong> {new Date(entry.Time).toLocaleString()}</p>
          <p><strong> </strong> {entry.Quote}</p>
        </div>
      ))}
    </div>
  );
}

export default PublicDiary;

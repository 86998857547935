import React from 'react';
import './App.css';
import logo from './logo.png'; // Ensure the path to your logo is correct

function LandingPage() {
  return (
    <div className="landing-page-container">
      <img src={logo} alt="Jhabits logo" className="logo" />
      <h1>Welcome to Jhabits</h1>
      <h2>Vocalize Your Life, See Your Journey</h2>
      <p>Jhabits revolutionizes how we journal and visualize our habits. It has never been easier to make an entry in your personal Diary.</p>
      {/* Add more content and styling as needed */}
    </div>
  );
}

export default LandingPage;

import React, { useState } from 'react';
import './App.css';
import logo from './logo.png'; // Ensure the path to your logo is correct

const AudioRecorder = () => {
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioUrl, setAudioUrl] = useState('');
  const [audioBlob, setAudioBlob] = useState(null);
  const [transcription, setTranscription] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); //Used to control the greying out of the send button
  const [buttonText, setButtonText] = useState('Send to Public Diary');


  const startRecording = async () => {
    // Reset the state to clear any previous recordings or transcriptions
    setAudioBlob(null);
    setAudioUrl('');
    setTranscription('');
    setRecording(true);
    setIsButtonDisabled(false);
    setButtonText('Send to Public Diary');

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const newRecorder = new MediaRecorder(stream);
      const audioChunks = [];

      newRecorder.ondataavailable = (e) => audioChunks.push(e.data);

      newRecorder.onstop = async () => {
        // Create the audio blob from the chunks
        const newAudioBlob = new Blob(audioChunks, { type: 'audio/webm' });
        setAudioBlob(newAudioBlob);
        const newAudioUrl = URL.createObjectURL(newAudioBlob);
        setAudioUrl(newAudioUrl);

        // Proceed to send the audio for transcription
        await sendAudioForTranscription(newAudioBlob); // Pass the blob directly
      };

      setMediaRecorder(newRecorder);
      newRecorder.start();
    } catch (err) {
      console.error("Error accessing the microphone:", err);
      setRecording(false); // Ensure recording is set to false if starting fails
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      setRecording(false);
      mediaRecorder.stop(); // This will also trigger the onstop event and thus the transcription
      mediaRecorder.stream.getTracks().forEach(track => track.stop());
    } else {
      console.error('Recorder is not recording or not ready.');
    }
  };

  const toggleRecording = async () => {
    recording ? stopRecording() : await startRecording();
  };

  const sendAudioForTranscription = async (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob, 'audiofile.webm');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/transcribe-audio`, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.json();
      setTranscription(data.transcription);
    } catch (error) {
      console.error('Error sending audio for transcription:', error);
    }
  };

  const saveToDiary = async () => {
    setIsButtonDisabled(true);
    setButtonText('Sent'); 
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/save-to-diary`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: 'Your Title Here',
          quote: transcription,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log("Entry saved successfully.");
    } catch (error) {
      console.error('Error saving transcription to diary:', error);
    }
  };

  return (
    <div className="recorder-container">
      <p className="instruction-text">Tap the button to start the recording, Tap again to stop.</p>
      <div className="button-container">
        <button onClick={toggleRecording} className={`record-btn ${recording ? 'recording' : ''}`}>
          {recording ? 
            <img src={logo} alt="Stop Recording" /> : // Replace with your stop icon path
            <img src={logo} alt="Start Recording" /> // Replace with your start icon path
          }
        </button>
      </div>
      {transcription && (
        <>
          <div className="transcription-container">
            <p>Transcription: {transcription}</p>
          </div>
          <div className="button-container">
            <button onClick={saveToDiary} className="send-btn" disabled={isButtonDisabled}>{buttonText}</button>
          </div>
        </>
      )}
    </div>
  );
};


export default AudioRecorder;

import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav>
      <Link to="/">Home</Link>
      <Link to="/talk">Talk</Link>
      <Link to="/public-diary">Public Diary</Link>
      <Link to="/private-diary">Private Diary</Link>       
    </nav>
  );
}

export default Navbar;

